import {useCallback, useEffect, useState} from "react";
import axios from "axios";

const reloadPage = () => window.location.reload(true)

const currentVersion = window.__APP_VERSION__ ?? "unknown"

export const UPDATE_STATUS = {
  checking: "checking",
  current: "current",
  available: "available",
}

export const useUpdateCheck = ({
  interval,
  type,
  ignoreServerCache,
}) => {
  const [status, setStatus] = useState(UPDATE_STATUS.checking)

  const checkUpdate = useCallback(() => {

    if(!window.__APP_VERSION_FILE__ || !window.__APP_VERSION__ || currentVersion === "unknown") {
      console.warn("No version file detected or unknown version")
      return
    }

    if(typeof currentVersion === "undefined") {
      setStatus(UPDATE_STATUS.current)
      return
    }

    setStatus(UPDATE_STATUS.checking)

    let requestStr = `/${window.__APP_VERSION_FILE__}`

    if(ignoreServerCache) {
      requestStr = `${requestStr}?v=${Date.now()}`
    }

    axios.get(requestStr)
      .then((res) => res.data)
      .then((data) => {
        if(data.version === currentVersion) {
          setStatus(UPDATE_STATUS.current)
        } else {
          setStatus(UPDATE_STATUS.available)
        }
      })

  }, [ignoreServerCache])

  useEffect(() => {
    if (status !== UPDATE_STATUS.current) {
      return;
    }

    if (type === 'interval') {
      const timeoutId = window.setTimeout(
        () => checkUpdate(),
        interval || 10000,
      );

      return () => {
        window.clearTimeout(timeoutId);
      };
    }
  }, [type, interval, status, checkUpdate]);

  useEffect(() => {
    if (type !== 'manual') {
      checkUpdate();
    }
  }, [checkUpdate, type]);

  return {
    status, reloadPage, checkUpdate
  }
}
