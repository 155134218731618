import React, { Suspense, useEffect } from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import './scss/style.scss'
import Loading from './components/Loading'
import { useReactiveVar } from '@apollo/client'
import routes from './routes/routes'
import { NETWORK_STATE, setNetworkState } from './graphql/policies/network'
import { AuthProvider } from './hooks/auth-context'
import { setTerritoryJSONCB } from './graphql/policies/search'
import { fiveDigitRand } from './tools/random'
import StatusCheck from "./layout/Components/StatusCheck";

const router = createBrowserRouter(routes)

const App = () => {
  const networkState = useReactiveVar(setNetworkState)
  useEffect(() => {
    // Set some internals on startup
    setTerritoryJSONCB(fiveDigitRand())
  }, []);

  if(networkState === NETWORK_STATE.ERROR) {
    return <>
      Network Error
    </>
  }

  return (
    <Suspense fallback={<Loading />}>
      <AuthProvider>
        <StatusCheck />
        <RouterProvider router={router}></RouterProvider>
      </AuthProvider>
    </Suspense>
  )
}

export default App
