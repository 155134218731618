import React from "react";
import Sidebar from "./Components/Sidebar";
import {Outlet} from "react-router-dom";
import {AggSearchProvider} from "../contexts/agg-search";
import ToastContainer from "../components/Utility/Components/ToastContainer";
import ModalContainer from "../components/Utility/Components/ModalContainer";
import {GeoJSONDataProvider} from "../contexts/geojson-data";
import GenericErrorBoundary from "../components/Utility/GenericErrorBoundary";

const BaseLayout = ({children}) => {
  return <>
        <ToastContainer/>
        <ModalContainer/>
        <Sidebar/>

        {children}


    <Outlet/>
  </>
}

export default BaseLayout
