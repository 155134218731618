import React, {useEffect} from "react";
import {useUpdateCheck} from "../../hooks/update-notification";
import {CButton, CModal, CModalBody, CModalHeader, CModalTitle} from "@coreui/react-pro";
import {CModalFooter} from "@coreui/react";

const StatusCheck = () => {

  const {status, reloadPage} = useUpdateCheck({type: "initial", interval: 5000, ignoreServerCache: true})

  useEffect(() => {
    if(status === "available") {
      document.body.style.overflow = "hidden"
    }
  }, [status]);

  if(status === "available") {
    return <div>
      <CModal
        visible={true}
        className={"blur-overlay"}
      >
        <CModalHeader closeButton={false}>
          <CModalTitle>
            An version update has been detected
          </CModalTitle>
        </CModalHeader>
        <CModalBody>
          Monitor has been updated. Press Reload to fetch the new version.
        </CModalBody>
        <CModalFooter>
          <CButton color={"primary"} onClick={() => reloadPage()}>Reload</CButton>
        </CModalFooter>
      </CModal>
    </div>
  }

  return <></>
}

export default StatusCheck
